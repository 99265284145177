<template>
  <app-dialog v-model="dialog" :title="title" no-padding>
    <template #toolbar>
      <v-btn icon text small @click="getDataPr(true)" :loading="dialog_pr.loading">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-data-table :options.sync="dialog_pr.datatable_options" :loading="dialog_pr.datatable.loading" :loading-text="dialog_pr.datatable.loadingText" :headers="dialog_pr.datatable.columns" :items="dialog_pr.datatable.data" :search="dialog_pr.search" :server-items-length="dialog_pr.datatable.totalRows" :items-per-page="dialog_pr.datatable_options.itemsPerPage" dense class="my-0" hide-default-footer @page-count="dialog_pr.pageCount = $event">
      </v-data-table>
    </v-card>
  </app-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
  },
  data() {
    return {
      dialog: false,
      title: "Detail Purchase Request",
      dialog_pr: {
        title: "Add from PR NAV",
        loading: null,
        search: "",
        datatable_options: {
          itemsPerPage: 10
        },
        itemsPerPageOption: [10, 20, 50, 100, 500],
        totalPage: 0,
        datatable: {
          loading: null,
          loadingText: "Loading... Please wait...",
          totalRows: 0,
          columns: [
            { text: "PR No.", value: "nav_pr_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Line No.",value: "nav_pr_line_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Item No.",value: "nav_pr_item_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description", value: "nav_pr_description", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description 2", value: "nav_pr_description2", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Specification", value: "nav_pr_specification", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Due Date", value: "nav_pr_due_date", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Campaign No.", value: "nav_pr_campaign_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "UoM", value: "nav_pr_uom", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Quantity", value: "nav_pr_quantity", class: "text-no-wrap", cellClass: "text-no-wrap py-0 text-end" },
          ],
          data: [],
        },
        showFilter: false,
        filter: {
          requester_division: null,
        },
        requesterDivisionList: [],
        loading_apply: false,

      },
      tender_detail_id: null,
    }
  },
  methods: {
    showDialog(tender_detail_id) {
      this.tender_detail_id = tender_detail_id;
      this.dialog = true;

      this.getDataPr(true);
    },
    async getDataPr(refresh) {
      const uri = "tender/linked-detail-pr";
      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.dialog_pr.datatable.loading = true;
      this.dialog_pr.filter["tender_detail_id"] = this.tender_detail_id;
      var formData = this.dataTableFormData(this.dialog_pr.datatable_options, this.dialog_pr.search, this.dialog_pr.filter);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.dialog_pr.datatable.data = resData.data.results;
            this.dialog_pr.datatable.totalRows = resData.data.paging.total_rows;
            this.dialog_pr.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.dialog_pr.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.dialog_pr.datatable.loading = false;
        });
    },
  }
}
</script>