<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>

    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableDetailBtn" @click="redirect('Purchasing.Tender.Detail', { id: tender.id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
    <app-button :disabled="!enableEditBtn" @click="redirect('Purchasing.Tender.Edit', { id: tender.id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableSaveBtn" @click="updateData()" title="Save" mdi-icon="mdi-content-save"></app-button>
    <app-button :disabled="!enableReleaseBtn" @click="releaseData()" title="Release Tender" mdi-icon="mdi-check-circle" :loading="loading_release"></app-button>
    <app-button :disabled="!enableReopenBtn" @click="reopenData()" title="Re-Open Tender" mdi-icon="mdi-reply" :loading="loading_reopen"></app-button>
    <app-button :disabled="!enableCloseBtn" @click="closeData()" title="Close Tender" mdi-icon="mdi-stop-circle" :loading="loading_close" color="red"></app-button>
    <!-- <app-button :disabled="!enablePublishBtn" @click="publishData()" title="Publish/Unpublish Tender" :mdi-icon="(!tender.published) ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline'" :loading="loading_publish"></app-button> -->
    <app-button :disabled="!enableDeleteBtn" @click="deleteData()" title="Delete" mdi-icon="mdi-delete" :loading="loading_delete"></app-button>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_publish: false,
      loading_release: false,
      loading_close: false,
      loading_reopen: false,
      data_detail: {},
    }
  },
  watch: {
    dataDetail() {
      this.data_detail = this.dataDetail;
    }
  },
  computed: {
    tender() {
      return (typeof this.dataDetail.tender != 'undefined') ? this.dataDetail.tender : {};
    },
    user() {
      return (typeof this.dataDetail.user != 'undefined') ? this.dataDetail.user : {};
    },
    enableDetailBtn() {
      if (this.$route.name !== 'Purchasing.Tender.Detail') {
        return true;
      }
      return false;
    },
    enableReleaseBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        return false;
      }
      if (this.tender.status === 'open') {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        return false;
      }
      if (this.tender.status != 'open') {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        return false;
      }
      if (this.tender.status != 'released') {
        return true;
      }
      return false;
    },
    enablePublishBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        return false;
      }
      if (!this.user.exists) {
        return false;
      }
      return true;
    },
    enableEditBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        return false;
      }
      if (this.$route.name !== 'Purchasing.Tender.Edit') {
        if (this.tender.status == 'open') {
          return true;
        }
      }
      return false;
    },
    enableSaveBtn() {
      if (this.$route.name === 'Purchasing.Tender.Edit') {
        if (this.tender.status == 'open') {
          return true;
        }
      }
      return false;
    },
    enableDeleteBtn() {
      if (this.tender.status === 'open') {
        return true;
      }
      return false;
    },
  },
  methods: {

    refreshDetail() {
      this.$emit('refresh-detail')
    },

    releaseData() {
      let post = async () => {
        this.loading_release = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.tender.id);
        await this.$axios
          .post("tender/release", formData)
          .then((res) => {
            this.loading_release = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_release = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Release tender?";
      this.showConfirm("Confirm", msg, post);
    },

    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.tender.id);
        await this.$axios
          .post("tender/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Release tender?";
      this.showConfirm("Confirm", msg, post);
    },

    closeData() {
      let post = async () => {
        this.loading_close = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.tender.id);
        await this.$axios
          .post("tender/close", formData)
          .then((res) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Release tender?";
      this.showConfirm("Confirm", msg, post);
    },

    publishData() {
      let post = async () => {
        this.loading_publish = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.tender.id);
        await this.$axios
          .post("tender/publish", formData)
          .then((res) => {
            this.loading_publish = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_publish = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Publish/Unpublish tender?";
      this.showConfirm("Confirm", msg, post);
    },

    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("tender/delete/" + this.tender.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.redirect("Purchasing.Tender")
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete tender?", post);
    },

    updateData() {
      this.$emit("btn-save-action")
    },


  }
}
</script>